<template>
  <DataTable
    :selectAll="selectAll"
    :dataKey="dataKey"
    :value="value"
    responsiveLayout="scroll"
    :rowHover="true"
    :lazy="true"
    :alwaysShowPaginator="false"
    :loading="isLoading"
    :paginator="true"
    :rows="perPage"
    :totalRecords="allItems"
    :removableSort="true"
    :rowClass="rowClass"
    :rowsPerPageOptions="[10, 20, 50]"
    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
    @selectAllChange="selectAllChange"
    @page="$emit('page', $event)"
    @sort="$emit('sort', $event)"
    @update:selection="$emit('update:selection', $event)">
    <template v-for="(slot, index) of Object.keys($slots)" :key="index" v-slot:[slot]>
      <slot :name="slot" />
      <Column
        v-for="col of columns"
        :field="col.field"
        :header="col.header"
        :key="col.field"
        :sortable="col.sortable" />
    </template>
    <template v-slot:empty>
      <h4 class="text-center py-2">No items found</h4>
    </template>
  </DataTable>
</template>
<script setup>
import DataTable from "primevue/datatable";
import Column from "primevue/column";

defineProps({
  dataKey: {
    type: String,
    required: true,
  },
  value: {
    type: Array,
    default: () => [],
  },
  columns: {
    type: Array,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  selectAll: {
    type: [Boolean, null],
    default: null,
  },
  rowClass: {
    type: Function,
    default: () => {},
  },
  perPage: {
    type: Number,
    default: 10,
  },
  allItems: {
    type: Number,
    default: 10,
  },
});
const $emit = defineEmits(["update:selection", "sort", "page", "selectAllChange"]);
const selectAllChange = ({ checked }) => {
  $emit("selectAllChange", checked);
};
</script>
<style scoped></style>
