export const dateConvertor = (date, isUnix = false, separator = "-") => {
  return new Date(isUnix ? date * 1000 : date)
    .toLocaleDateString("en-GB")
    .replaceAll("/", separator);
};

export const dateToUnixTimestamp = (date) =>
  date ? Math.floor(new Date(date).getTime() / 1000) : Date.now();

export const getExportFileName = () => {
  const date = new Date()
    .toLocaleString("en-GB")
    .replace(", ", "_")
    .replaceAll("/", ".")
    .replaceAll(":", "-");
  return `payments_${date}.csv`;
};

export const fileSaver = (link, fileName) => {
  const tmpLinkTag = document.createElement("a");
  tmpLinkTag.href = link;
  tmpLinkTag.setAttribute("download", `${fileName}`);
  document.body.appendChild(tmpLinkTag);
  tmpLinkTag.click();

  // clearing
  document.body.removeChild(tmpLinkTag);
};
